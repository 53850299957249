import React, { useCallback, useEffect, useState } from "react";

import { debounce } from "lodash";
import { Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { getShipmentDetailReport } from "api/shipment";
import { getUsers } from "api/user";

import { ExternalLink, InternalLink } from "components/Custom/Links";
import {
  DatePickerGroup,
  Dropdown,
  InputContainer,
  TextInput,
} from "components/Form";
import { FormattedTextField } from "components/FormElements";
import PageTitle from "components/PageTitle";
import DefaultTable from "components/Table";
import Widget from "components/Widget";

import { useLayoutDispatch, popupNotification } from "context/LayoutContext";

import { Class, Person, QrCode2, SpaceBar, TextSnippet } from "icons";

import { getCarrierTrackingLink } from "pages/shipments/utils";

import {
  EcommerceShipmentDetailStatus,
  initialAPIListValue,
} from "utils/constants";
import { convertDataToTableView } from "utils/convert";
import { convertDate, formatDateList, timezoneFormat } from "utils/date";
import { exportToExcel } from "utils/exportToExcel";
import getClearAdornment from "utils/form";
import { getQueryParams } from "utils/router";
import { toTitleCase } from "utils/string";

export default function TransactionReport({ location }) {
  const search = location.search.substring(1);
  const query = search ? getQueryParams(search) : null;
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [data, setData] = useState(initialAPIListValue);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [pickers, setPickers] = useState(initialAPIListValue);
  const [filters, setFilters] = useState({
    sku: "",
    ship_via: "",
    location: "",
    start_date: convertDate(new Date()),
    end_date: convertDate(new Date()),
    customer: "",
    status: query ? query.status : EcommerceShipmentDetailStatus[0],
    assignee: "",
    picker: "",
    shipper: "",
  });
  const [helpText, setHelpText] = useState(
    "This page shows the current statuses of the records",
  );
  const [headerCounts, setHeaderCounts] = useState({});

  const layoutDispatch = useLayoutDispatch();

  const cells = [
    {
      id: "shipment_number",
      style: {
        minWidth: "120px",
      },
      render: (r) => (
        <InternalLink to={`/app/shipments/${r.shipment_id}`}>
          {r.shipment_number}
        </InternalLink>
      ),
    },
    { id: "customer" },
    { id: "product_sku", exportLabel: "Inventory Id" },
    {
      id: "location",
      render: (r) => r.location_code,
      style: { minWidth: "120px" },
    },
    { id: "qty", exportLabel: "Quantity", render: (r) => r.quantity },
    { id: "ship_via", style: { minWidth: "160px" } },
    {
      id: "tracking_number",
      render: (r) => (
        <ExternalLink
          to={getCarrierTrackingLink(r.ship_via, r.tracking_number)}
        >
          {r.tracking_number}
        </ExternalLink>
      ),
    },
    {
      id: "printed -> assigned -> picked -> shipped",
      render: (r) => {
        return formatDateList([
          r.printed_at ? r.printed_at.replace(" (UTC)", "-04:00") : "N/A",
          r.assigned_at ? r.assigned_at.replace(" (UTC)", "-04:00") : "N/A",
          r.picked_at ? r.picked_at.replace(" (UTC)", "-04:00") : "N/A",
          r.shipped_at ? r.shipped_at.replace(" (UTC)", "-04:00") : "N/A",
        ]);
      },
      style: { minWidth: "320px" },
    },
    {
      id: "assignee -> picker -> shipper",
      render: (r) => `
          ${r.assignee ? toTitleCase(r.assignee) : "N/A"} ->
          ${r.picker ? toTitleCase(r.picker) : "N/A"} ->
          ${r.shipper ? toTitleCase(r.shipper) : "N/A"}
        `,
    },
  ];

  useEffect(() => {
    if (error) {
      popupNotification({
        dispatch: layoutDispatch,
        message: error,
      });
      setError(false);
    }
  }, [error, layoutDispatch]);

  useEffect(() => {
    if (data.isFetching) {
      getShipmentDetailReport({
        query: { ...filters, limit: rowsPerPage, offset: page * rowsPerPage },
        responseSetter: (res) => {
          setData({
            items: res.details,
            count: res.count,
            isFetching: false,
          });
          setHeaderCounts(res.customCounts);
        },
      });
    }
  }, [data, page, filters, rowsPerPage]);

  useEffect(() => {
    if (pickers.isFetching) {
      getUsers({
        params: {
          group: "Picker",
          user__is_active: true,
        },
        setFunc: setPickers,
        paginated: true,
      });
    }
  }, [pickers.isFetching]);

  const handleChangeFilter = (prop) => ({ target: { value } }) => {
    setFilters({ ...filters, [prop]: value });
    debouncedSearchHandler();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setData({ ...data, isFetching: true });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setData({ ...data, isFetching: true });
  };

  const exportData = (data) => {
    const today = new Date();
    exportToExcel(
      [],
      `transaction-report-${filters.status}-${today.toLocaleDateString()}`,
      true,
      convertDataToTableView(data.items, [
        {
          id: "shipment_number",
        },
        {
          id: "customer",
        },
        {
          id: "product_sku",
          exportLabel: "Inventory Id",
        },
        {
          id: "location_code",
          exportLabel: "Location",
        },
        {
          id: "quantity",
        },
        {
          id: "ship_via",
        },
        {
          id: "tracking_number",
        },
        {
          id: "printed_at",
          render: (r) =>
            r.printed_at
              ? timezoneFormat(r.printed_at.replace(" (UTC)", "-04:00"))
              : "-",
        },
        {
          id: "assigned_at",
          render: (r) =>
            r.assigned_at
              ? timezoneFormat(r.assigned_at.replace(" (UTC)", "-04:00"))
              : "-",
        },
        {
          id: "picked_at",
          render: (r) =>
            r.picked_at
              ? timezoneFormat(r.picked_at.replace(" (UTC)", "-04:00"))
              : "-",
        },
        {
          id: "shipped_at",
          render: (r) =>
            r.shipped_at
              ? timezoneFormat(r.shipped_at.replace(" (UTC)", "-04:00"))
              : "-",
        },
        {
          id: "assignee",
          render: (r) => toTitleCase(r.assignee),
        },
        {
          id: "picker",
          render: (r) => toTitleCase(r.picker),
        },
        {
          id: "shipper",
          render: (r) => toTitleCase(r.shipper),
        },
      ]),
      setExportDisabled,
    );
  };

  const handleExportData = () => {
    getShipmentDetailReport({
      query: { ...filters, limit: 10000, offset: 0 },
      responseSetter: (res) => {
        exportData({ items: res.details, count: res.count, isFetching: false });
      },
    });
  };

  const clearHandler = (input) => {
    setFilters({ ...filters, [input]: "" });
    debouncedSearchHandler();
  };

  const clearAdornment = (input) =>
    getClearAdornment(input, clearHandler, filters);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce(() => {
      setData({ ...data, isFetching: true });
    }, 500),
    [],
  );

  return (
    <>
      <PageTitle title="Transaction Report" helpText={helpText} />
      <InputContainer style={{ marginBottom: "1rem" }}>
        <Grid container spacing={0}>
          <DatePickerGroup
            startDateValue={filters.start_date}
            startDateOnChange={handleChangeFilter("start_date")}
            endDateValue={filters.end_date}
            endDateOnChange={handleChangeFilter("end_date")}
            itemGridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
            onMouseEnter={() => setHelpText("Applies on print date")}
            onMouseLeave={() =>
              setHelpText("This page shows the current statuses of the records")
            }
          />
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Dropdown
              id="status"
              title="Status"
              value={filters.status}
              onChange={handleChangeFilter("status")}
              items={EcommerceShipmentDetailStatus.map((item) => ({
                value: item,
                label: item,
              }))}
              LeftIcon={Class}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Customer"
              variant="outlined"
              value={filters.customer || ""}
              onChange={handleChangeFilter("customer")}
              InputProps={clearAdornment("customer")}
              isUpperCase={true}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="SKU"
              variant="outlined"
              value={filters.sku || ""}
              onChange={handleChangeFilter("sku")}
              InputProps={clearAdornment("sku")}
              LeftIcon={QrCode2}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Ship Via"
              variant="outlined"
              value={filters.ship_via || ""}
              onChange={handleChangeFilter("ship_via")}
              InputProps={clearAdornment("ship_via")}
              LeftIcon={TextSnippet}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Autocomplete
              fullWidth
              value={
                filters.assignee
                  ? pickers.items.find(
                      (u) => u.user.username === filters.assignee,
                    )
                  : null
              }
              options={pickers.items}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.display_name ? option.display_name : "Select user"
              }
              onChange={(e, user) => {
                setFilters({
                  ...filters,
                  assignee: user && user.user ? user.user.username : "",
                });
                setData({ ...data, isFetching: true });
              }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={"Assignee"}
                  variant="outlined"
                  LeftIcon={Person}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Autocomplete
              fullWidth
              value={
                filters.picker
                  ? pickers.items.find(
                      (u) => u.user.username === filters.picker,
                    )
                  : null
              }
              options={pickers.items}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.display_name ? option.display_name : "Select user"
              }
              onChange={(e, user) => {
                setFilters({
                  ...filters,
                  picker: user && user.user ? user.user.username : "",
                });
                setData({ ...data, isFetching: true });
              }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={"Picker"}
                  variant="outlined"
                  LeftIcon={Person}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Autocomplete
              fullWidth
              value={
                filters.shipper
                  ? pickers.items.find(
                      (u) => u.user.username === filters.shipper,
                    )
                  : null
              }
              options={pickers.items}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.display_name ? option.display_name : "Select user"
              }
              onChange={(e, user) => {
                setFilters({
                  ...filters,
                  shipper: user && user.user ? user.user.username : "",
                });
                setData({ ...data, isFetching: true });
              }}
              renderInput={(params) => (
                <TextInput
                  {...params}
                  label={"Shipper"}
                  variant="outlined"
                  LeftIcon={Person}
                />
              )}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <FormattedTextField
              fullWidth
              label="Location"
              variant="outlined"
              value={filters.location || ""}
              onChange={handleChangeFilter("location")}
              InputProps={clearAdornment("location")}
              isLocationCode={true}
              LeftIcon={SpaceBar}
            />
          </Grid>
        </Grid>
      </InputContainer>
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <DefaultTable
              headers={cells}
              headerCounts={headerCounts}
              data={data}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleExportData={handleExportData}
              exportDisabled={exportDisabled}
              setExportDisabled={setExportDisabled}
              filters={filters}
            />
          </Grid>
        </Grid>
      </Widget>
    </>
  );
}
