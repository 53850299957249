import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { getInventories } from "api/inventory";
import { createOrderPick } from "api/movement";
import { getProductsWithAttributes } from "api/products";
import { getShipmentDetails } from "api/shipment";

import {
  MarkLocationAsInspected,
  MobileResetButton,
  SetBuggyToLocal,
  Spacer,
} from "components/Custom";
import { Button } from "components/Form";
import { TextInputWithKeyboard } from "components/FormElements";
import PageTitle from "components/PageTitle";
import Widget from "components/Widget";

import { popupNotification, useLayoutDispatch } from "context/LayoutContext";

import { Left, Right, SortAscending, SortDescending, SpaceBar } from "icons";

import { initialAPIListValue } from "utils/constants";
import { getWithExpiry } from "utils/storage";
import {
  locationCodeValidation,
  productAttributeValidation,
} from "utils/validations";

import _ from "lodash";

const initialFormData = {
  location_id: "",
  location_code: "",
  product_id: "",
  product_sku: "",
  qty: "",
  step: "location",
};

export default function LTLPick() {
  const { shipment_id } = useParams();
  const history = useHistory();
  const locationEl = useRef();
  const productEl = useRef();
  const quantityEl = useRef();
  const layoutDispatch = useLayoutDispatch();
  let buggyCode = getWithExpiry("buggyCode");
  let buggyId = getWithExpiry("buggyId");
  const [buggy, setBuggy] = useState({ id: buggyId, code: buggyCode });
  const [details, setDetails] = useState(initialAPIListValue);
  const [sortedItems, setSortedItems] = useState([]);
  const [detail, setDetail] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [locationSort, setLocationSort] = useState("asc");
  const [formData, setFormData] = useState(initialFormData);
  const [alternateLocations, setAlternateLocations] = useState([]);

  useEffect(() => {
    if (shipment_id) {
      getShipmentDetails({
        params: {
          shipment_id,
          limit: 100,
          status: "Unassigned,Assigned,Picking",
        },
        responseSetter: (res) => {
          const { results } = res;
          if (!results.length) {
            popupNotification({
              dispatch: layoutDispatch,
              message: "Shipment not found!",
            });
            history.push("/app/ltl/pick-list");
          }
          setDetails({
            items: results,
            isFetching: false,
          });
        },
        paginated: true,
      });
    }
  }, [shipment_id, layoutDispatch, history]);

  useEffect(() => {
    if (details.items.length && locationSort) {
      const sortedByLocation = _.orderBy(
        details.items,
        (item) => item.location.code,
        [locationSort],
      );
      setSortedItems(sortedByLocation);
      setDetail(sortedByLocation[0]);
    }
  }, [details.items, locationSort]);

  const handleIconClick = () => {
    setLocationSort((prev) => (prev === "asc" ? "desc" : "asc"));
    locationEl.current.focus();
  };

  const handleLocationSubmit = (value) => {
    let location_code = locationCodeValidation(value.trim());
    if (!location_code) {
      location_code = value.trim();
    }

    const matchDetail = sortedItems.find(
      (item) => item.location.code === location_code,
    );
    if (matchDetail) {
      setDetail(matchDetail);
      setFormData({
        ...formData,
        location_id: matchDetail.location.id,
        location_code: matchDetail.location.code,
        product_sku: matchDetail.product.sku,
        step: "product",
      });
      return;
    }

    const matchLocation = alternateLocations.find(
      (location) => location.code === location_code,
    );
    if (matchLocation) {
      setFormData({
        ...formData,
        location_id: matchLocation.id,
        location_code: matchLocation.code,
        step: "product",
      });
      return;
    }

    popupNotification({
      dispatch: layoutDispatch,
      message: "Location Not Found!",
    });
  };

  const handleProductSubmit = (value) => {
    const formatted_value = productAttributeValidation(value);
    const attribute = formatted_value ? formatted_value : value;
    // Search sku in details

    if (detail.product.sku === attribute) {
      setFormData({
        ...formData,
        product_id: detail.product.id,
        step: "qty",
      });
      return;
    }

    getProductsWithAttributes({
      params: {
        value_iexact: attribute,
      },
      responseSetter: (res) => {
        const { results } = res;
        if (!results.length) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Product not found!",
          });
          productEl.current.value = "";
          return;
        }

        if (results.length > 1) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Duplicate Items!",
          });
          return;
        }
        const productAttribute = results[0];
        if (formData.product_sku !== productAttribute.product_sku) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Product Mismatch!",
          });
          return;
        }

        setFormData({
          ...formData,
          product_id: productAttribute.product,
          step: "qty",
        });
      },
    });
  };

  const handleAlternateLocations = () => {
    getInventories({
      params: {
        product_id: detail.product.id,
        location__location_type:
          detail.product.product_type_display === "Rug" ? 1 : 2,
      },
      responseSetter: (res) => {
        const { results } = res;
        const filteredLocations = results.filter(
          (row) => row.location.id !== formData.location_id,
        );
        if (!filteredLocations.length) {
          popupNotification({
            dispatch: layoutDispatch,
            message: "Alternate Location not found!",
          });
          return;
        }
        setAlternateLocations(
          filteredLocations.slice(0, 3).map((row) => ({
            id: row.location.id,
            code: row.location.code,
            qty: row.qty,
          })),
        );
        setFormData({
          ...formData,
          location_code: "",
          location_id: "",
          step: "location",
        });
      },
    });
  };

  const getRemainingQty = () => {
    const totalPickQty = detail.transactions
      .filter((t) => t.action_type === "Picked")
      .reduce((total, transaction) => total + transaction.quantity, 0);
    return detail.quantity - totalPickQty;
  };

  const clearAndFocusElement = (ref) => {
    ref.current.value = "";
    ref.current.focus();
  };

  const handleSubmit = () => {
    if (isNaN(formData.qty)) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity must be integer!",
      });
      clearAndFocusElement(quantityEl);
      return;
    }

    if (formData.qty > 999 || formData.qty < 1) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity must be between 1 and 999!",
      });
      clearAndFocusElement(quantityEl);
      return;
    }
    const remainingQty = getRemainingQty(detail);
    if (formData.qty > remainingQty) {
      popupNotification({
        dispatch: layoutDispatch,
        message: "Quantity cannot be more than the remaining quantity.",
      });
      clearAndFocusElement(quantityEl);
      return;
    }

    setIsFetching(true);
    createOrderPick({
      body: {
        detail_id: detail.id,
        buggy_id: buggy.id,
        location_id: formData.location_id,
        product_id: formData.product_id,
        quantity: Number(formData.qty),
      },
      responseSetter: (res) => {
        const { success, message } = res;
        if (!success) {
          setIsFetching(false);
          return popupNotification({
            dispatch: layoutDispatch,
            message,
          });
        }

        const remainingQty = getRemainingQty();
        if (Number(remainingQty) === Number(formData.qty)) {
          const filterItems = sortedItems.filter((si) => si.id !== detail.id);
          setSortedItems(filterItems);
          setDetail(filterItems[0]);
        } else {
          setSortedItems(
            sortedItems.map((si) => {
              if (si.id === detail.id) {
                return {
                  ...si,
                  transactions: [
                    ...si.transactions,
                    {
                      action_type: "Picked",
                      quantity: Number(formData.qty),
                    },
                  ],
                };
              }
              return si;
            }),
          );
          setDetail((prev) => ({
            ...prev,
            transactions: [
              ...prev.transactions,
              {
                action_type: "Picked",
                quantity: Number(formData.qty),
              },
            ],
          }));
        }

        popupNotification({
          dispatch: layoutDispatch,
          message: "Picked!",
          status: "success",
        });

        if (
          !sortedItems.length ||
          (sortedItems.length === 1 &&
            Number(remainingQty) === Number(formData.qty))
        ) {
          history.push("/app/ltl/pick-list");
          return;
        }

        setFormData(initialFormData);
        setAlternateLocations([]);
        setIsFetching(false);
      },
    });
  };

  const changeDetail = (direction) => {
    const index = sortedItems.findIndex((s) => s.id === detail.id);
    const maxIndex = sortedItems.length - 1;
    if (direction === "next") {
      setDetail(sortedItems[index === maxIndex ? 0 : index + 1]);
    } else {
      setDetail(sortedItems[index === 0 ? maxIndex : index - 1]);
    }
    setAlternateLocations([]);
    locationEl.current.focus();
  };

  if (details.isFetching || !sortedItems.length) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageTitle title="Pick" helpText="LTL" />
      <Spacer height="1rem" />
      <Grid container>
        <Grid item xs={12}>
          <SetBuggyToLocal onAssign={setBuggy} />
        </Grid>
      </Grid>
      {!buggy.id ? null : (
        <Widget>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Shipment :{" "}
                <strong>{details.items[0].shipment.shipment_number}</strong>
              </Typography>
              {Object.keys(detail).length && detail.note ? (
                <Alert variant="filled" severity="warning">
                  {detail.note}
                </Alert>
              ) : null}
              {sortedItems.length && !formData.location_code ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => changeDetail("prev")}
                      style={{ marginLeft: "-1rem" }}
                    >
                      <Left />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body2">
                      {Object.keys(detail).length
                        ? `${detail.product.sku} (${
                            detail.quantity - getRemainingQty()
                          }/${detail.quantity})`
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => changeDetail("next")}>
                      <Right />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null}

              {formData.location_code && (
                <Typography>
                  <MarkLocationAsInspected
                    location_code={formData.location_code}
                    location_id={formData.location_id}
                    afterConfirm={() => {
                      setFormData((prev) => ({
                        ...prev,
                        location_code: "",
                        location_id: "",
                        step: "location",
                      }));
                      setAlternateLocations([]);
                    }}
                  />
                  {formData.step === "product" && (
                    <Button
                      variant="contained"
                      color="blue"
                      onClick={handleAlternateLocations}
                    >
                      Alternate Locations
                    </Button>
                  )}
                </Typography>
              )}
              <Typography
                hidden={!formData.product_sku || !formData.product_id}
              >
                <strong>SKU : </strong>
                {detail.product ? (
                  <>
                    {detail.product.sku}{" "}
                    <strong>
                      ({detail.quantity - getRemainingQty()}/{detail.quantity})
                    </strong>
                  </>
                ) : null}
              </Typography>
            </Grid>
            {formData.step === "location" && (
              <>
                {alternateLocations.length > 0 ? (
                  <Grid item xs={12}>
                    <List>
                      {alternateLocations.map((location) => (
                        <ListItem>
                          {location.code} (<strong>{location.qty}</strong>)
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextInputWithKeyboard
                    fullWidth
                    autoFocus
                    inputRef={locationEl}
                    id="location_code"
                    variant="outlined"
                    label="Location"
                    placeholder={
                      !alternateLocations.length ? detail.location.code : null
                    }
                    LeftIcon={
                      locationSort === "asc" ? SortAscending : SortDescending
                    }
                    onIconClick={handleIconClick}
                    onKeyPress={({ target, key }) =>
                      key === "Enter"
                        ? handleLocationSubmit(target.value)
                        : null
                    }
                    hasBorder
                  />
                </Grid>
              </>
            )}
            {formData.step === "product" && (
              <>
                <Grid item xs={12}>
                  <TextInputWithKeyboard
                    fullWidth
                    autoFocus
                    inputRef={productEl}
                    id="product_id"
                    variant="outlined"
                    label="SKU"
                    placeholder={detail.product.sku}
                    LeftIcon={SpaceBar}
                    onKeyPress={({ target, key }) =>
                      key === "Enter" ? handleProductSubmit(target.value) : null
                    }
                    hasBorder
                  />
                </Grid>
              </>
            )}
            {formData.step === "qty" && (
              <>
                <Grid item xs={12}>
                  <TextInputWithKeyboard
                    fullWidth
                    autoFocus
                    id="qty"
                    inputRef={quantityEl}
                    variant="outlined"
                    label={`Quantity: (${getRemainingQty()})`}
                    LeftIcon={SpaceBar}
                    onChange={({ target: { value } }) =>
                      setFormData((prev) => ({ ...prev, qty: value }))
                    }
                    onKeyPress={({ key }) => {
                      if (key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="blue"
                    disabled={!formData.qty || isFetching}
                    onClick={handleSubmit}
                  >
                    {isFetching ? <CircularProgress /> : "Submit"}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Widget>
      )}
      <MobileResetButton
        onClick={() => {
          setFormData(initialFormData);
          setDetail(sortedItems[0]);
        }}
      />
    </>
  );
}
